<template>
  <div class="canna_start_pageZone">
    <div class="canna_login_zone"
      
      :class="{
        'canna_login_zoneIntro':( (is_Mobile || $q.screen.lt.md) && currentView==='main') ? false:true ,
        'canna_account_zone': ( ['rc_cof','rc_nombre','sc_pass','sc_login','rc_email','rc_sms'].indexOf(currentView)>=0 ) ? true : false
      }"
    >
      <!-- :style="  '' + ( (is_Mobile || $q.screen.lt.md) && currentView==='intro' ? 'flex-direction: row;' : '' ) " -->
      <!-- Pantalla inicial -->
      <template v-if="currentView==='intro'">

        <div id="login_container_frame" class="login_container_frame loginReset_container_frame">

          <div class="main_title">
            Restablecer contraseña
          </div>
          <div class="main_subtitle spacerRowTop32">
            Elige una de las siguientes opciones para recibir 
            <template v-if="($q.screen.gt.xs)"><br></template>
            las instrucciones que te permitan restablecer tu contraseña.
          </div>
          <div class="canna_formZone spacerRowTop32"  :style=" (is_Mobile || $q.screen.lt.sm) ? '' : 'width: 374px !important;' ">
              <ValidationObserver ref="formResetPass" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormRP"
                  @submit.prevent="handleSubmit(onPassMode_Submit)"
                  @reset="onPassModeReset"
                  class="canna_forma_zona"
                  style=""
                >

                  <div class="col col-xs-12 col-md-12 canna_forma_campo_container">
                    <q-radio 
                      :color="'cannaMainColor'"
                      v-model="resetMode"
                      name="tiporecover_pass"
                      :val="1" 
                    >
                      <template v-slot>
                        <div style="margin-top: 0px;">
                          Utilizar mis claves oficiales (CURP, RFC, NSS)
                        </div>
                      </template>
                    </q-radio>
                    <!-- keep-color -->
                  </div>
                  <div class="col col-xs-12 col-md-12 canna_forma_campo_container">
                    <q-radio 
                      :color="'cannaMainColor'"
                      v-model="resetMode"
                      name="tiporecover_pass"
                      :val="2" 
                    >
                      <template v-slot>
                        <div style="margin-top: 0px;">
                          Recibir un correo electrónico con las <template v-if="($q.screen.gt.xs)"><br></template> instrucciones para restablecer mi contraseña
                        </div>
                      </template>
                    </q-radio>
                    <!-- keep-color -->
                  </div>
                  <div class="col col-xs-12 col-md-12 canna_forma_campo_container">
                    <q-radio 
                      :color="'cannaMainColor'"
                      v-model="resetMode"
                      name="tiporecover_pass"
                      :val="3" 
                    >
                      <template v-slot>
                        <div style="margin-top: 0px;">
                          Recibir un mensaje de texto (sms) con las <template v-if="($q.screen.gt.xs)"><br></template>
                          instrucciones para restablecer mi contraseña
                        </div>
                      </template>
                    </q-radio>
                    <!-- keep-color -->
                  </div>

                  <ValidationProvider 
                    ref="fldx_tiporecover" 
                    name="tiporecover" 
                    :immediate="true"
                    tag="div"
                    class=""
                    :rules=" { required: true, oneOf: [1,2,3] } " 
                    v-slot="{ errors, invalid, validated  }"
                  >
                    <input type="hidden" name="tipoperfil" v-model="resetMode">
                    <template v-if="(invalid && validated)">
                      <div class="q-field--error" style="margin-top:5px; display:none;">
                        <div class="q-field__bottom" style="padding-left: 10px;">
                          {{ (errors[0] !==undefined) ?  errors[0].replace('tiporecover','Modo de recuperación').replace('El campo','') : '' }}
                        </div>
                      </div>
                    </template>
                  </ValidationProvider>

                  <div class="buttonZone spacerRowTop32">
                    <q-btn unelevated  label="Continuar" type="submit" class="buttonSave" color="app_mainBtn" 
                      :style="'width:100%;'"
                      :disable="(passed || valid )? false : true"
                    />
                  </div>

                </q-form>
              </ValidationObserver>

          </div>
          <div class="main_subtitle main_actionText action_item spacerRowTop24"
            :class="{'spacerRowTop40' : ($q.screen.lt.sm) ? true : false}" 
            @click.stop="gotoLogin_Page"
          >
            Regresar para iniciar sesión
          </div>

        </div>

      </template>

      <!-- rc_cof - MODO (claves oficiales) -->
      <template v-if="currentView === 'rc_cof' ">
        <!-- Pantalla 2 -->
        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Restablecer contraseña
          </div>

          <div class="main_subtitle spacerRowTop24">
            Para poder restablecer la contraseña de tu <template v-if="($q.screen.gt.xs)"><br></template> 
            cuenta ingresa tus claves CURP, RFC y NSS.
          </div>

          <div class="spacerRowTop26">

              <ValidationObserver ref="formstepCof" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormStepCof"
                  @submit.prevent="handleSubmit(onStepFormSubmit_cof)"
                  @reset="onStepFormReset_cof"
                  class="canna_forma_zona"
                  style="padding-bottom: 50px;"
                >
                  <div class="canna_formZone">

                    <!-- Campo de CURP --> <!-- dev-sprint17 nuevo flujo -->
                    <ValidationProvider rules="required|cannaCURP|emojiFree|min:18|max:18" name="curp" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container" style="margin-top: 20px;">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >CURP</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          name="curp"
                          v-model="resetData.curp"
                          :dense="true"
                          mask="AAAA######AAAAAAX#"
                          fill-mask
                          :label="(resetData.curp==='')? '':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? ( errors[0].indexOf('ya ha sido registrada')>=0 ? errors[0] : errores.curp) : ''"
                          :disabled="processingData"
                          @blur="paso_cof_onBlurCampo('curp', $event)"
                          @input="val => { resetData.curp = val.toUpperCase() }"
                        ><!-- ENER 2022 se actualizo a "AAAA######AAAAAAX#"  en vez de  "AAAA######AAAAAA##" --> 
                        </q-input>
                      </div>
                    </ValidationProvider>

                    <!-- Campo de RFC --> <!-- dev-sprint17 nuevo flujo -->
                    <ValidationProvider rules="required|cannaRFC|emojiFree|min:15|max:15" name="rfc" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container" style="margin-top: 16px;">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >RFC</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          name="rfc"
                          v-model.trim="resetData.rfc"
                          :dense="true"
                          :mask="'AAAA-######-XXX'"
                          fill-mask
                          :label="(resetData.rfc==='')? '':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? ( errors[0].indexOf('ya ha sido registrada')>=0 ? errors[0] : errores.rfc) : ''"
                          :disabled="processingData"
                          @blur="paso_cof_onBlurCampo('rfc', $event)"
                          @input="val => { resetData.rfc = val.toUpperCase() }"
                        >
                        </q-input>
                      </div>
                    </ValidationProvider>

                    <!-- Campo de NSS --> <!-- dev-sprint17 nuevo flujo -->
                    <ValidationProvider rules="required|cannaNSS|emojiFree|min:15|max:15" name="nss" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container" style="margin-top: 16px;">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >NSS</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          name="nss"
                          v-model="resetData.nss"
                          :mask="'##-##-##-####-#'"
                          fill-mask
                          :dense="true"
                          :label="(resetData.nss==='')? '':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? ( errors[0].indexOf('ya ha sido registrada')>=0 ? errors[0] : errores.nss) : ''"
                          :disabled="processingData"
                          @blur="paso_cof_onBlurCampo('nss', $event)"
                        >
                        </q-input>
                      </div>
                    </ValidationProvider>

                  </div>

                  <template v-if="(frmMainErrors.paso_rc_cof !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                      :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                      :class="{
                        'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                      }"
                    >
                      <div style="display: flex;margin-bottom: 12px;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.pasoA"></div>
                      </div>
                    </div>
                  </template>

                  <div class="main_subtitle main_actionText action_item spacerRowTop24"
                    :class="{'spacerRowTop40' : ($q.screen.lt.sm) ? true : false}" 
                    @click.stop="gotoLogin_Page"
                  >
                    Regresar para iniciar sesión
                  </div>

                  <div class="bottom_account_zone spacerRowTop40">
                    <q-btn type="button" unelevated  label="< Atras"  class="buttonSave btnAccountAction" color="app_mainBtn"
                      @click.stop="goToPrev_Step(1)"
                    />
                    <q-btn type="submit" unelevated  label="Siguiente >"  class="buttonSave btnAccountAction" color="app_mainBtn"
                      :disable="(passed || valid)? false : true"
                    />
                  </div>

                </q-form>

              </ValidationObserver>

          </div>

        </div>

      </template>

      <!--  rc_email -->
      <template v-if="currentView==='rc_email'">

        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Restablecer contraseña
          </div>

          <div class="main_subtitle spacerRowTop24">
            Ingresa tu dirección de correo electrónico para que podamos enviarte <template v-if="($q.screen.gt.xs)"><br></template> 
            las instrucciones que te permitan restablecer tu contraseña.
          </div>

          <div class="spacerRowTop26">

              <ValidationObserver ref="formstepEmail" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormStepEmail"
                  @submit.prevent="handleSubmit(onStepFormSubmit_mail)"
                  @reset="onStepFormReset_mail"
                  class="canna_forma_zona"
                  style="padding-bottom: 50px;"
                >
                  <div class="canna_formZone">

                    <!-- Campo de email -->
                    <ValidationProvider 
                      ref="fldz_email"
                      rules="required|email|emojiFree|max:255" name="Email" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Correo electrónico</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          name="email"
                          type="email"
                          v-model.trim="resetData.email"
                          hint=""
                          :dense="true"
                          :label="(resetData.email==='')?'ejemplo@email.com':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : ''"
                          :disabled="processingData"
                          @input="val => { resetData.email = ('' + val).trim()} "
                           @blur="paso_email_onBlurCampo('email', $event)"
                        />
                      </div>
                    </ValidationProvider>

                  </div>

                  <template v-if="(frmMainErrors.paso_rc_mail !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                      :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                      :class="{
                        'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                      }"
                    >
                      <div style="display: flex;margin-bottom: 12px;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.paso_rc_mail"></div>
                      </div>
                    </div>
                  </template>

                  <div class="main_subtitle main_actionText action_item spacerRowTop24"
                    :class="{'spacerRowTop40' : ($q.screen.lt.sm) ? true : false}" 
                    @click.stop="gotoLogin_Page"
                  >
                    Regresar para iniciar sesión
                  </div>

                  <div class="bottom_account_zone spacerRowTop40">
                    <q-btn type="button" unelevated  label="< Atras"  class="buttonSave btnAccountAction" color="app_mainBtn" @click.stop="goToPrev_Step(2)" />
                    <q-btn type="submit" unelevated  label="Siguiente >"  class="buttonSave btnAccountAction" color="app_mainBtn" :disable="(passed || valid)? false : true"/>
                  </div>

                </q-form>

              </ValidationObserver>

          </div>

        </div>
      </template>

      <!-- rc_sms -->
      <template v-if="currentView==='rc_sms'">
      
        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Restablecer contraseña
          </div>

          <div class="main_subtitle spacerRowTop24">
            Ingresa tu número celular para que podamos enviarte las <template v-if="($q.screen.gt.xs)"><br></template> 
            instrucciones que te permitan restablecer tu contraseña.
          </div>

          <div class="spacerRowTop26">

              <ValidationObserver ref="formstepSms" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormStepSms"
                  @submit.prevent="handleSubmit(onStepFormSubmit_sms)"
                  @reset="onStepFormReset_sms"
                  class="canna_forma_zona"
                  style="padding-bottom: 50px;"
                >
                  <div class="canna_formZone">

                    <!-- Campo de Telefono (lada y numero) --> <!-- lada por el momento es FIJO -->
                    <div class="spacerRowTop20" style="display: flex;justify-content: space-between;">
                      <div class="fieldLabel text-left">Número celular</div>
                      <div class="requiredLabel text-right" >
                        Obligatorio
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: row; align-items: self-start;">

                      <div class="canna_forma_campo_container" style="max-width: 90px;">
                        <q-input
                          filled
                          disable
                          readonly
                          name="lada"
                          v-model="resetData.lada"
                          hint=""
                          :dense="true"
                          :label="(resetData.lada==='')? '':''"
                          :disabled="processingData"
                        />
                      </div>

                      <ValidationProvider 
                        rules="required|cannaPhones|emojiFree|min:10|max:10" name="telefono" 
                        tag="div" class="canna_forma_campo_container canna_forma_campo_celularA" style="margin-left:10px;flex: 1;"
                        :immediate="false" v-slot="{ errors, invalid, validated  }"
                      >
                          <q-input
                            filled
                            name="telefono"
                            v-model="resetData.phone"
                            hint=""
                            :dense="true"
                            mask="(##) #### ####"
                            unmasked-value
                            :label="(resetData.phone==='')? 'Número celular a 10 dígitos':''"
                            :error="invalid && validated"
                            :error-message="(errors[0] !==undefined) ? errores.phone : ''"
                            :disabled="processingData"
                            @blur="paso_sms_onBlurCampo('telefono', $event)"
                          />

                      </ValidationProvider>
                    </div>


                  </div>

                  <template v-if="(frmMainErrors.paso_rc_mail !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                      :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                      :class="{
                        'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                      }"
                    >
                      <div style="display: flex;margin-bottom: 12px;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.paso_rc_mail"></div>
                      </div>
                    </div>
                  </template>

                  <div class="main_subtitle main_actionText action_item spacerRowTop24"
                    :class="{'spacerRowTop40' : ($q.screen.lt.sm) ? true : false}" 
                    @click.stop="gotoLogin_Page"
                  >
                    Regresar para iniciar sesión
                  </div>

                  <div class="bottom_account_zone spacerRowTop40">
                    <q-btn type="button" unelevated  label="< Atras"  class="buttonSave btnAccountAction" color="app_mainBtn"
                      @click.stop="goToPrev_Step(3)"
                    />
                    <q-btn type="submit" unelevated  label="Siguiente >"  class="buttonSave btnAccountAction" color="app_mainBtn"
                      :disable="(passed || valid)? false : true"
                    />
                  </div>

                </q-form>

              </ValidationObserver>

          </div>

        </div>
      
      </template>

      <!-- rc_nombre (todas las modalidades)  -->
      <template v-if="currentView==='rc_nombre'">
        
        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Restablecer contraseña
          </div>

          <div class="main_subtitle spacerRowTop24">
            Ahora escribe tu nombre completo.
          </div>

          <div class="spacerRowTop26">

              <ValidationObserver ref="formstepNames" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormStepNames"
                  @submit.prevent="handleSubmit(onStepFormSubmit_names)"
                  @reset="onStepFormReset_names"
                  class="canna_forma_zona"
                  style="padding-bottom: 50px;"
                >
                  <div class="canna_formZone">

                    <!-- Campo de nombre -->
                    <ValidationProvider rules="required|cannaTextNamesLetras|emojiFree|min:2|max:50" name="nombre" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Nombre</div>
                          <div class="requiredLabel text-right" >Obligatorio</div>
                        </div>
                        <q-input
                          filled
                          name="nombre"
                          v-model="resetData.nombre"
                          hint="Escribe tu nombre o nombres"
                          :dense="true"
                          :label="(resetData.nombre==='')? 'Escribe tu nombre(s)':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.nombre : ''"
                          @blur="paso_names_onBlurCampo('nombre', $event)"
                        />
                      </div>
                    </ValidationProvider>

                    <!-- Campo de apellido paterno -->
                    <ValidationProvider rules="required|cannaTextNamesLetras|emojiFree|min:2|max:50" name="paterno" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container spacerRowTop24">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Apellido paterno</div>
                          <div class="requiredLabel text-right" >Obligatorio</div>
                        </div>
                        <q-input
                          filled
                          name="paterno"
                          v-model="resetData.paterno"
                          hint=""
                          :dense="true"
                          :label="(resetData.paterno==='')?'Escribe tu apellido paterno':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.paterno : ''"
                          :disabled="processingData"
                          @blur="paso_names_onBlurCampo('paterno', $event)"
                        />
                      </div>
                    </ValidationProvider>

                    <!-- Campo de materno -->
                    <ValidationProvider 
                      :rules="{
                        required:(resetData.sin_materno===true) ? false : true, 
                        cannaTextNamesLetras:true, 
                        emojiFree:true, 
                        min:2,
                        max:50
                      }" name="materno" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container spacerRowTop24">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Apellido materno</div>
                          <div class="requiredLabel text-right" >Obligatorio</div>
                        </div>
                        <q-input
                          filled
                          name="materno"
                          v-model="resetData.materno"
                          hint=""
                          :disable="(resetData.sin_materno) ? true : false "
                          :dense="true"
                          :label="(resetData.materno==='' && resetData.sin_materno===false)?'Escribe tu apellido materno':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ? errores.materno : ''"
                          :disabled="processingData"
                          @blur="paso_names_onBlurCampo('materno', $event)"
                        >
                        </q-input>
                      </div>
                    </ValidationProvider>

                    <div class="canna_forma_campo_container">
                      <div style="display: flex;align-items: baseline;">
                        <div style="margin-left: -10px;">
                          <q-checkbox 
                            color="cannaMainColor"
                            v-model="resetData.sin_materno"
                            @input="(val, evt) => { paso_names_maternoInput(val, evt) }"
                          />
                        </div>
                        <div>
                          <div class="labelLegend_a">Legalmente no tengo apellido materno</div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <template v-if="(frmMainErrors.paso_rc_names !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                      :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                      :class="{
                        'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                      }"
                    >
                      <div style="display: flex;margin-bottom: 12px;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.paso_rc_names"></div>
                      </div>
                    </div>
                  </template>

                  <div class="main_subtitle main_actionText action_item spacerRowTop24"
                    :class="{'spacerRowTop40' : ($q.screen.lt.sm) ? true : false}" 
                    @click.stop="gotoLogin_Page"
                  >
                    Regresar para iniciar sesión
                  </div>

                  <div class="bottom_account_zone spacerRowTop40">
                    <q-btn type="button" unelevated  label="< Atras"  class="buttonSave btnAccountAction" color="app_mainBtn"
                      @click.stop="goToPrev_Step"
                    />
                    <q-btn type="submit" unelevated  label="Siguiente >"  class="buttonSave btnAccountAction" color="app_mainBtn"
                      :disable="(passed || valid)? false : true"
                    />
                  </div>

                </q-form>

              </ValidationObserver>

          </div>

        </div>


      </template>

      <!-- solo con claves oficiales  -->
      <template v-if="currentView==='sc_pass'">

        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Restablecer contraseña
          </div>

          <div class="main_subtitle spacerRowTop24">
            Tu nueva contraseña debe tener entre 8 y 12 caracteres. Para que sea <template v-if="($q.screen.gt.xs)"><br></template> 
            segura debes incluir en ella números, letras y signos de puntuación.
          </div>

          <div class="spacerRowTop26">

              <ValidationObserver ref="formResetPX" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
                <q-form
                  ref="myFormResetPX"
                  @submit.prevent="handleSubmit(onStepFormSubmit_passX)"
                  @reset="onStepFormReset_passX"
                  class="canna_forma_zona"
                  style="padding-bottom: 50px;"
                >
                  <div class="canna_formZone">

                    <!-- Campo de password -->
                    <ValidationProvider rules="required|cannaTextPasswordAlt|emojiFree|min:8|max:12" name="newPassWord"
                       :immediate="false" v-slot="{ errors, invalid, validated  }"
                    >
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Nueva contraseña</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(screen_passA===true) ? 'password' : 'text'"
                          name="newPassWord"
                          v-model="resetPass.password"
                          hint=""
                          :dense="true"
                          :label="(resetPass.password==='')?'Escribe tu nueva contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('newPassWord','Contraseña').replace('El campo','La') : ''"
                          :disabled="processingData"
                          @blur="pasox_onBlurNewPass('newpass', $event)"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="screen_passA = !screen_passA"
                              v-show="(resetPass.password.length>=1) ? true : false "
                            >
                              {{(screen_passA===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>
                        </q-input>
                      </div>
                    </ValidationProvider>

                    <!-- Campo de confirmacion de password -->
                    <ValidationProvider rules="required|cannaTextPasswordConfAlt:@newPassWord|emojiFree|min:8|max:12" name="confpassword" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container" style="margin-top: 20px;">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Confirma tu nueva contraseña</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(screen_passB===true) ? 'password' : 'text'"
                          name="confpassword"
                          v-model="resetPass.confpassword"
                          hint=""
                          :dense="true"
                          :label="(resetPass.confpassword==='')?'Escribe nuevamente tu contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('confpassword','Confirmacion de contraseña').replace('El campo','La') : ''"
                          :disabled="processingData"
                          @blur="pasox_onBlurNewPass('confPass', $event)"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="screen_passB = !screen_passB"
                              v-show="(resetPass.confpassword.length>=1) ? true : false "
                            >
                              {{(screen_passB===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>
                        </q-input>
                      </div>
                    </ValidationProvider>

                  </div>

                  <template v-if="(frmMainErrors.formResetPX !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                      :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                      :class="{
                        'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                      }"
                    >
                      <div style="display: flex;margin-bottom: 12px;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.formResetPX"></div>
                      </div>
                    </div>
                  </template>

                  <div class="buttonZone spacerRowTop60" style="display: flex;justify-content: center;"> <!-- style="margin-top: 60px;" -->
                    <q-btn unelevated  label="Restablecer contraseña" type="submit" class="buttonSave" color="app_mainBtn" :disabled="processingData" 
                      :style="  (is_Mobile || $q.screen.lt.sm) ? 'width:100%;' : 'width:336px;' "
                      :disable="((passed || valid))? false : true"
                    />
                  </div>

                </q-form>

              </ValidationObserver>

          </div>

        </div>

      </template>

      <!--  Login de usuario (solo con claves oficiales)-->
      <template v-if="currentView==='sc_login' ">

        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Tu contraseña ha sido restablecida
          </div>

          <div class="main_subtitle spacerRowTop24">
            Para inciar sesión ingresa tu dirección de correo electrónico <template v-if="($q.screen.gt.xs)"><br></template> 
            y la nueva contraseña que creaste.
          </div>

          <div class="spacerRowTop26">

              <ValidationObserver ref="formCannaLog" tag="div" class="" v-slot="{ handleSubmit, valid, passed }">
              
                <q-form
                  ref="myFormCannaLog"
                  @submit.prevent="handleSubmit(onStepFormSubmit_loginX)"
                  @reset="onStepFormReset_loginX"
                  class="canna_forma_zona"
                  style="padding-bottom: 50px;"
                >
                  <div class="canna_formZone">


                    <!-- Campo de email -->
                    <ValidationProvider 
                      ref="fldzz_email"
                      rules="required|email|emojiFree|max:255" name="email" :immediate="false" v-slot="{ errors, invalid, validated  }">
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Correo electrónico</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          name="nombre"
                          type="email"
                          v-model.trim="loginData.email"
                          hint=""
                          :dense="true"
                          :label="(loginData.email==='')?'ejemplo@email.com':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('Email','Correo electrónico').replace('El campo','El') : ''"
                          :disabled="processingData"
                          @input="val => { loginData.email = ('' + val).trim()} "
                          @blur="pasox_onBlurLogin('email', $event)"
                        />
                      </div>
                    </ValidationProvider>

                    <!-- Campo de password -->
                    <ValidationProvider 
                      ref="fldz_password"
                      rules="required|cannaTextPassword|emojiFree|min:8|max:12" 
                      name="password" :immediate="false" v-slot="{ errors, invalid, validated  }"
                    >
                      <div class="canna_forma_campo_container">
                        <div style="display: flex;justify-content: space-between;">
                          <div class="fieldLabel text-left"
                            :class="{
                              'fieldError':invalid && validated
                            }"
                          >Contraseña</div>
                          <div class="requiredLabel text-right" >
                            Obligatorio
                          </div>
                        </div>
                        <q-input
                          filled
                          :type="(screen_pass===true) ? 'password' : 'text'"
                          name="password"
                          v-model="loginData.password"
                          hint=""
                          :dense="true"
                          :label="(loginData.password==='')?'Escribe tu contraseña':''"
                          :error="invalid && validated"
                          :error-message="(errors[0] !==undefined) ?  errors[0].replace('PassWord','Contraseña').replace('El campo','La') : ''"
                          :disabled="processingData"
                          @blur="pasox_onBlurLogin('email', $event)"
                        >
                          <template v-slot:append>
                            <div class="inputLabelAction noselect action_item" @click.stop="screen_pass = !screen_pass"
                              v-show="(loginData.password.length>=1) ? true : false "
                            >
                              {{(screen_pass===true) ? 'MOSTRAR' : 'OCULTAR'}}  <!-- <q-icon name="r_star" /> -->
                            </div>
                          </template>
                        </q-input>
                      </div>
                    </ValidationProvider>

                  </div>

                  <template v-if="(frmMainErrors.formCannaLog !== '')">
                    <div class="canna_frmMainErrorZone spacerRowTop24" style="display: flex;flex-direction: column;align-items: center;justify-content: center;"
                      :style="(is_Mobile || $q.screen.lt.sm) ? 'min-width: 368px; max-width: 362px;' : ''"
                      :class="{
                        'canna_formZone': (is_Mobile || $q.screen.lt.sm) ? true : false
                      }"
                    >
                      <div style="display: flex;margin-bottom: 12px;">
                        <div style="margin-right:10px;">
                          <img src="images/login_warning.png"/>
                        </div>
                        <div style="flex:1;" v-html="frmMainErrors.formCannaLog"></div>
                      </div>
                    </div>
                  </template>

                  <div class="buttonZone spacerRowTop60" style="display: flex;justify-content: center;"> <!-- style="margin-top: 60px;" -->
                    <q-btn unelevated  label="Iniciar sesión" type="submit" class="buttonSave" color="app_mainBtn" :disabled="processingData" 
                      :style="  (is_Mobile || $q.screen.lt.sm) ? 'width:100%;' : 'width:336px;' "
                      :disable="((passed || valid))? false : true"
                    />
                  </div>

                </q-form>

              </ValidationObserver>

          </div>

        </div>

      </template>

      <!-- sc_confirm -->
      <template v-if="currentView==='sc_confirm'">
   
        <div class="contenedor_account_b">

          <div class="main_title spacerRowTop48">
            Restablecer contraseña
          </div>

          <div class="main_subtitle spacerRowTop24">
            &nbsp;
          </div>

          <div class="spacerRowTop26">

                <div class="canna_forma_zona noselect" style="padding-bottom: 50px;">

                  <div class="canna_formZone" v-if="resetMode===3">

                    <div style="text-align: center;">
                      Se ha enviado un mensaje de texto al número <span style="color: #7156DC;">  
                        {{ '** **** ' + this.resetData.phone.substr(-4)}}
                      </span> con las 
                      <template v-if="($q.screen.gt.xs)"><br></template> 
                      instrucciones para que puedas restablecer tu contraseña.
                    </div>

                    <div style="margin-top: 32px;text-align: center;">
                      <template v-if="pomodoro.canSendLink===false">
                        <span class="">Podrás solicitar un nuevo mensaje de texto en {{pomodoro.timerCount}} segundos.</span>
                      </template>
                      <template v-else>
                        ¿No has recibido el mensaje de texto? Da <span class="main_actionText action_item" @click.stop="onStepFormSubmit_names(true)">clic aquí</span>
                      </template>
                    </div>

                    <div class="main_actionText" style="margin-top: 24px;text-align: center; display:flex; justify-content:center;">
                      <div class="action_item" @click.stop="gotoLogin_Page">
                        <q-icon name="arrow_back" style="font-size: 20px;" /> Volver a la pantalla de Inicio de Sesión
                      </div>
                    </div>

                  </div>

                  <div class="canna_formZone" v-if="resetMode===2">

                    <div style="text-align: center;">
                      Se ha enviado un correo electrónico a la dirección  <template v-if="($q.screen.gt.xs)"><br></template> 
                      <span style="color: #7156DC;">
                        {{ '****' + this.resetData.email.substr(4) }}
                      </span> con las instrucciones  <template v-if="($q.screen.gt.xs)"><br></template> 
                      para que puedas restablecer tu contraseña.
                    </div>
                    <div style="margin-top: 32px;text-align: center;">
                      ¿No has recibido el correo electrónico? Por favor asegúrate de revisar <template v-if="($q.screen.gt.xs)"><br></template> 
                      tu carpeta de correo no deseado, si aún no lo encuentras prueba con <template v-if="($q.screen.gt.xs)"><br></template> 
                      <div>
                        <template v-if="pomodoro.canSendLink===false">
                          <span class="">Podrás solicitar un nuevo correo en {{pomodoro.timerCount}} segundos.</span>
                        </template>
                        <template v-else>
                          <span class="main_actionText action_item"  @click.stop="onStepFormSubmit_names(true)">Enviar otro correo electrónico</span>
                        </template>
                      </div>
                    </div>

                    <div class="main_actionText" style="margin-top: 24px;text-align: center; display:flex; justify-content:center;">
                      <div class="action_item" @click.stop="gotoLogin_Page">
                        <q-icon name="arrow_back" style="font-size: 20px;" /> Volver a la pantalla de Inicio de Sesión
                      </div>
                    </div>

                  </div>

                </div>

          </div>

        </div>

      
      </template>

      <!-- noInfoError -->
      <template v-if="currentView==='noInfoError'">

        <div id="login_container_frame" class="login_container_frame loginReset_container_frame" >

          <div class="main_title">
            Restablecer contraseña
          </div>
          <div class="main_subtitle spacerRowTop32">
            No se encontró ninguna cuenta de Canna <br>
            con la información que proporcionaste.
          </div>

          <div class="buttonZone spacerRowTop32" style="text-align: center;">
            <q-btn unelevated  label="Volver a intentar"
              type="button" class="buttonSave" color="app_mainBtn"
              :style="'width:327px;'"
              @click.stop="doRestartRecover"
            />
          </div>

        </div>

      </template>


    </div>

    <q-inner-loading :showing="loading_Process" class="app_innerLoader" style="top: -51px;z-index: 9000;">
      <q-spinner-orbit size="100px" color="indigo" />
    </q-inner-loading>

  </div>
</template>
<script>
import { requestPack } from './../../boot/axios' // APP code 
import { canna_cookieMethods } from './../../boot/__cannav2_app'

import logOut_Mixin from './../../mixins/requestMix.js'
import myplatform_Mixin from './../../mixins/platformMix.js'
import helperBase_Mixin from './../../mixins/helperBase.js'

export default {
  name: 'canna_login_screen',
  mixins:[
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  components:{

  },
  data () {
    return {
      version: '1.0.1',
      debugEls: false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      loading_Process: false,
      initialView: 'intro',
      currentView: 'intro', 
      /*
        'intro',
        rc_cof ,

        rc_email,
        rc_sms ,

        rc_nombre, (pantalla de nombres)
        sc_pass ,  (pantalla password directo)
        sc_login,  (pantalla para login)

        sc_confirm (pantalla para confirmar envio x email o sms)
        noInfoError (si no hay info q concuerde )
      */
      screen_pass: true, //para controlar el mostrar ocultar en el input
      loginData: {
        email: '', 
        password: ''
      },
			resetMode: null , // 1 (claves Of)  // 2: email  // 3: sms 
      resetPass:{
        password: '',
        confpassword: '',
        resetKey: '',
        userId: ''
      },
      screen_passA: true, //para controlar el mostrar ocultar en el input
      screen_passB: true, //para controlar el mostrar ocultar en el input
      resetData: {
        email: '',
        lada:'MX (+52)',
				phone: '',
				paterno: '',
				materno: '',
        sin_materno: false, 
				nombre: '',
				curp: '', 
				rfc: '',
				nss: ''
      },
      errores:{
        email: 'El dato ingresado no tiene el formato de un correo electrónico. Por favor, revisa e inténtalo de nuevo.', 
        password: 'La contraseña no cumple con los requisitos mínimos.', //Por favor, revisa las indicaciones e inténtalo de nuevo.
        passwordAlt: 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.',
        nombre: 'Este dato debe contener entre 2 y 50 caracteres solo pueden ser letras. Por favor, revisa e inténtalo de nuevo.',
        paterno: 'Este dato debe contener entre 2 y 50 caracteres solo pueden ser letras. Por favor, revisa e inténtalo de nuevo.',
        materno: 'Este dato debe contener entre 2 y 50 caracteres solo pueden ser letras. Por favor, revisa e inténtalo de nuevo.',
        phone: 'El número celular ingresado no es válido. Por favor, revisa e inténtalo de nuevo. ',
        curp: 'La clave CURP ingresada no es valida. Por favor, revisa e inténtalo de nuevo.',
        rfc: 'La clave RFC ingresada no es valida. Por favor, revisa e inténtalo de nuevo.',
        nss: 'La clave NSS ingresada no es valida. Por favor, revisa e inténtalo de nuevo.'
      },
      frmMainErrors:{
        pasoLogin: '',
        paso_rc_cof: '',
        paso_rc_mail: '',
        paso_rc_sms: '',
        paso_rc_names: '',
        formResetPX: '',
        formCannaLog: ''
      },
      frmMainRefs:{
        pasoLogin: [
          {fieldNameAPi: 'email', fieldNameForm: 'email' },
          {fieldNameAPi: 'password', fieldNameForm: 'password' }
        ],
        pasoForgot: [
          {fieldNameAPi: 'email', fieldNameForm: 'emailreset' }
        ],
        formResetPX: [],
        formCannaLog: [
          {fieldNameAPi: 'email', fieldNameForm: 'email' },
          {fieldNameAPi: 'password', fieldNameForm: 'password' }
        ],
      },
      pomodoro:{ // para reenvio de correo 
        timerX:null, 
        canSendLink:true , 
        timerCount: 60 , //60 segundos
      },       
    }
  },
  computed: {
    layoutParent () {
      return this.$parent.$parent.$parent
    }
  },
  watch: {
    pageWidth: function (newVal, oldVal) { 
      if (newVal!==oldVal){ 
        this.$forceUpdate()
      }
    },
    currentView : function (newVal, oldVal) { 
      // WIP
      if (newVal!==oldVal){ 
        if (newVal === 'rc_nombre') {
          setTimeout(() => {
            if (this.$refs.formstepNames) {
              this.$refs.formstepNames.reset()
            }
          }, 100)
        }
        if (newVal === 'sc_login') {
          setTimeout(() => {
            if (this.$refs.formCannaLog) {
              this.$refs.formCannaLog.reset()
            }
          }, 200)
        }
        if (newVal === 'sc_pass') {
          setTimeout(() => {
            if (this.$refs.formResetPX) {
              this.$refs.formResetPX.reset()
            }
          }, 200)
        }

        this.$forceUpdate()
      }
    },
    loading_Process:function (newVal, oldVal) { 
      if (newVal!==oldVal){ 
        this.processingData =newVal
        this.$forceUpdate()
      }
    },
    'loginData.password': function (newVal, oldVal) {
      if (newVal!==oldVal){ 
        if (newVal==='' || newVal===null){
          this.screen_pass = true
        }
      }
    }
  },
  methods: {
    ...requestPack,
    ...canna_cookieMethods,
    gotoLogin_Page (){
      var newRuta = '/'
      this.$router.push(newRuta)
    },
    doRestartRecover () { 
      this.resetInfo()
      this.currentView = 'intro'
    },
    goToPrev_Step ( dataEraseMode ) { 
      this.$cannaDebug('-- recoverScreen -- goToPrev Step')
			var newView = ''
			if ( ['rc_email','rc_sms','rc_cof'].indexOf(this.currentView)>=0 ) { 

				newView = 'intro'

			} else if ( this.currentView === 'rc_nombre'){

				newView = (this.resetMode===1) ? 'rc_cof' : 
										(this.resetMode===2) ? 'rc_email' : 
											(this.resetMode===3) ? 'rc_sms' :  ''
			} else { 
				return false
			}

			if (newView!==''){ 
				if (dataEraseMode!==undefined && dataEraseMode!=null) {
					this.resetDataBlocks(dataEraseMode)
				}
				this.currentView = newView
			} else { 
				return 
			}
    },
    //---------------------
    onPassMode_Submit() {
      this.$cannaDebug('-- recoverScreen -- onPassMode Submit')
      var newView = ''
			var resetMode  = this.resetMode 
      newView = (resetMode === 1) ? 'rc_cof' : (resetMode === 2) ? 'rc_email' : (resetMode === 3) ? 'rc_sms' : ''
      this.currentView =  newView
      this.$forceUpdate()
      return true
    },
    onPassModeReset () {
      this.resetMode = null
      this.$forceUpdate()
    },
		resetInfo () { 
			this.resetData = { email: '', phone: '' , lada:'MX (+52)', paterno: '', materno: '', sin_materno: false, nombre: '', curp: '',  rfc: '', nss: '' }
			this.currentView = 'intro'
			this.resetMode = null
		},
		resetDataBlocks ( modo ) { 
			if (modo === 1){ // pantalla cof 
				this.resetData.nss = ''
				this.resetData.curp= ''
				this.resetData.rfc = ''
			} else if (modo === 2){ //pantalla email 
				this.resetData.email = ''
			} else if (modo === 3){ //pantalla sms
				this.resetData.phone = ''
			} else if (modo === 4){ //pantalla nombres
				this.resetData.paterno = ''
				this.resetData.materno = ''
				this.resetData.sin_materno = false
				this.resetData.nombre  = ''
			} else { 
				return false 
			}
		},
    // 
    //---------------------
    pomodoroStart: function() {
      if (this.pomodoro.timerX!==null){ 
        return 
      }
      this.pomodoro.canSendLink = false;
      this.pomodoro.timerX = setInterval ( ()=> this.pomodoroDecreaseTimer(), 1000 );
    }, 
    pomodoroDecreaseTimer: function(){
      if (this.pomodoro.timerCount>=1){ 
        this.pomodoro.timerCount--; 
      } else { 
        this.pomodoro.timerCount=0;
        this.pomodoroHandlePause(); 
      }
    },
    pomodoroHandlePause: function (){ 
        clearInterval(this.pomodoro.timerX); 
        this.pomodoro.timerX = null;
        this.pomodoro.canSendLink = true;
        this.$forceUpdate();
    },
    //---------------------
    // Pantalla de Datos oficiales 
    onStepFormSubmit_cof : async function (){
      var myData = {
        curp: this.resetData.curp ,
        rfc: this.resetData.rfc ,
        nss: this.resetData.nss 
      }
      this.loading_Process = true
      await this._waitRequestProc(200)
      var mxModel = this.getModelRequest('recover_clavesOf')
      var reqRes = { action: [] }, reqErr = { action: 0 }
      var validConfig = this.createAxiosConfig ('get', mxModel, myData, false, null)
      await this.doRequestByPromise(this.$axios, 'validate_cof_recover', reqRes.action, validConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        var responseErr = reqRes.action[0].data.data
        if (responseErr !== null && typeof responseErr === 'object' && Array.isArray(responseErr) === false){          
          if (responseErr.message.indexOf('No se encontro el coincidencia con')>=0){ //"message": "No se encontro el coincidencia con los datos ingresados, por favor intentelo de nuevo.",
            this.currentView = 'noInfoError'
          } else { 
            this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          }
        } else { 
          this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        }
        this.loading_Process = false
        return false 
      }
      // TOdo bien
      await this._waitRequestProc(200)
      this.currentView = 'rc_nombre'
      this.loading_Process = false
      return true
    },
    onStepFormReset_cof () { 
      this.$cannaDebug('-- recoverScreen -- onStepFormReset cof')
    },
    paso_cof_onBlurCampo: function( fieldName , event ){ 
      this.$cannaDebug('-- recoverScreen -- pasoC onBlur Campo')
      if (this.frmMainErrors.paso_rc_cof !== ''){ 
        this.frmMainErrors.paso_rc_cof = ''
      }
      if (fieldName === 'rfc' ||fieldName === 'curp') { 
        if (fieldName === 'rfc') {
          if (this.resetData.rfc!==null && this.resetData.rfc!==''){
            this.resetData.rfc = this.resetData.rfc.toUpperCase()
          }
        }
        if (fieldName === 'curp') {
          if (this.resetData.curp!==null && this.resetData.curp!==''){
            this.resetData.curp = this.resetData.curp.toUpperCase()
          }
        }
      }
    },
    //--------------------
    // pantalla de Email
    onStepFormSubmit_mail: async function () {  //Ok 
      var myData = {
        email: this.resetData.email 
      }
      this.loading_Process = true
      await this._waitRequestProc(200)
      var mxModel = this.getModelRequest('recover_Email')
      var reqRes = { action: [] }, reqErr = { action: 0 }
      var validConfig = this.createAxiosConfig ('get', mxModel, myData, false, null)
      await this.doRequestByPromise(this.$axios, 'validate_email_recover', reqRes.action, validConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        var responseErr = reqRes.action[0].data.data
        if (responseErr !== null && typeof responseErr === 'object' && Array.isArray(responseErr) === false){          
          if (responseErr.message.indexOf('No se encontro el email')>=0){ //message": "No se encontro el email ingresado, por favor intentelo de nuevo.",
            this.currentView = 'noInfoError'
          } else { 
            this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          }
        } else { 
          this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        }
        this.loading_Process = false
        return false 
      }
      // TOdo bien 
      await this._waitRequestProc(200)
      this.currentView = 'rc_nombre'
      this.loading_Process = false
      return true 
    },
    onStepFormReset_mail () {
      //------
    },
    paso_email_onBlurCampo (fieldName , event){ 
      if (this.frmMainErrors.paso_rc_mail !== ''){ 
        this.frmMainErrors.paso_rc_mail = ''
      }
    },
    //--------------------
    // pantalla de SMS    
    onStepFormSubmit_sms: async function () {  //Ok 
      var myData = {
        phone:  '+52' + this.resetData.phone.trim() 
      }
      this.loading_Process = true
      await this._waitRequestProc(200)
      var mxModel = this.getModelRequest('recover_Sms')
      var reqRes = { action: [] }, reqErr = { action: 0 }
      var validConfig = this.createAxiosConfig ('get', mxModel, myData, false, null)
      await this.doRequestByPromise(this.$axios, 'validate_sms_recover', reqRes.action, validConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        var responseErr = reqRes.action[0].data.data
        if (responseErr !== null && typeof responseErr === 'object' && Array.isArray(responseErr) === false){          
          if (responseErr.message.indexOf('No se encontro el coincidencia con')>=0){ //"message": "No se encontro el coincidencia con los datos ingresados, por favor intentelo de nuevo.",
            this.currentView = 'noInfoError'
          } else { 
            this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          }
        } else { 
          this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        }
        this.loading_Process = false
        return false 
      }
      // TOdo bien 
      await this._waitRequestProc(200)
      this.currentView = 'rc_nombre'
      this.loading_Process = false
      return true 
    },
    onStepFormReset_sms () {
      //------
    },
    paso_sms_onBlurCampo (fieldName , event){ 
      if (this.frmMainErrors.paso_rc_sms !== ''){ 
        this.frmMainErrors.paso_rc_sms = ''
      }
    },
    //--------------------
    // Pantalla de Nombres 
    onStepFormSubmit_names: async function ( resendAction ) { 
      this.$cannaDebug('-- recoverScreen -- onStepFormSubmit names')
      var x = this.resetData
      var myData = { 
        nombre: x.nombre, 
        paterno: x.paterno
      }
      if (x.sin_materno ===true ) {
        myData.sin_materno = 1
        myData.materno = '' 
      } else {
        myData.sin_materno = 0
        myData.materno = x.materno
      }

      var mxModel = null 
      var reqRes = { action: [] }, reqErr = { action: 0 }

      // // 1 (claves Of)  // 2: email  // 3: sms 
      if (this.resetMode === 1) {  //claves oficiales 
        mxModel = this.getModelRequest('recover_clavesOf')

        myData.curp = x.curp 
        myData.rfc = x.rfc 
        myData.nss = x.nss 

      } else if (this.resetMode === 2) {  //Email 
        mxModel = this.getModelRequest('recover_Email')
        myData.email = x.email

      } else if (this.resetMode === 3) {  //Sms 
        mxModel = this.getModelRequest('recover_Sms')
        myData.phone ='+52' + this.resetData.phone.trim() 

      } else { 
        return false 
      }

      this.loading_Process = true
      await this._waitRequestProc(200)

      var myParams = { useData: true, data: myData } 
      var validConfig = this.createAxiosConfig ('post', mxModel, myParams, false, null)
      await this.doRequestByPromise(this.$axios, 'validate_names_recover', reqRes.action, validConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })

      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        var responseErr = reqRes.action[0].data.data
        if (responseErr !== null && typeof responseErr === 'object' && Array.isArray(responseErr) === false){          
          if (responseErr.message.indexOf('No se encontró ninguna cuenta de Canna')>=0){  //"message": "No se encontró ninguna cuenta de Canna con la información que proporcionaste.",
            this.currentView = 'noInfoError'
          } else { 
            this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          }
        } else { 
          this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        }
        this.loading_Process = false
        return false 
      }
      // Aqui todo bien 
      // // 1 (claves Of)  // 2: email  // 3: sms 
      var newView = ''
      var MSGTxt = ''

      if (this.resetMode === 1) {  //claves oficiales 
        var infox  = reqRes.action[0].data.data // Obtenemos el KEY para resetear la contraseña
        this.resetPass.resetKey = '' + infox.key 
        this.resetPass.userId = '' + infox.id
        newView = 'sc_pass'

      } else if (this.resetMode === 2) {  //Email 
        newView = 'sc_confirm'
        MSGTxt = 'Las instrucciones para restablecer tu contraseña han sido enviadas.'

      } else if (this.resetMode === 3) {  //Sms 
        newView = 'sc_confirm'
        MSGTxt = 'El mensaje de texto para restablecer tu contraseña ha sido enviado.'
      }
      await this._waitRequestProc(200)

      if (resendAction!=undefined && resendAction===true) { //Solo para email y SMS 

        this.pomodoro.canSendLink = false;
        this.pomodoro.timerCount = 60;
        this.pomodoroStart();
        this.$q.notify({ position: 'top', color:'cannaOKAlert', type: 'positive', message: MSGTxt, timeout: 7000 })
        this.loading_Process = false
        return true 

      }

      this.currentView = newView
      this.loading_Process = false
      return true
    }, 
    onStepFormReset_names () { 
      //------
    },
    paso_names_onBlurCampo (fieldName , event){ 
      if (this.frmMainErrors.paso_rc_names !== ''){ 
        this.frmMainErrors.paso_rc_names = ''
      }
    },
    paso_names_maternoInput (value, event){ 
      if (value === true){ 
        this.resetData.materno = ''
      }
      this.paso_names_onBlurCampo(materno, event)
      this.$forceUpdate() 
    },
    //-------------------- 
    // Pantalla de Reset Pass 
    onStepFormSubmit_passX: async function () {
      //this.$cannaDebug('-- recoverScreen -- onStepFormSubmit names')
      var mpModel = this.getModelRequest('passRenew')
      var reqRes = { action: [] }, reqErr = { action: 0 }
      this.loading_Process = true
      // ------------------
      var myResetConfig = this.createAxiosConfig ('post', mpModel, {
          delimiters:[ this.resetPass.userId],
          urlmixData:{ key: this.resetPass.resetKey, password: this.resetPass.password}
        }, false, null
      )
      await this._waitRequestProc(1000)
      // ------------------
      await this.doRequestByPromise(this.$axios, 'resetLogin', reqRes.action, myResetConfig).then((resx) => {
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.ex_handlerErrorFormNoSessionRequest (reqRes.action[0], reqErr.action, this.$refs.formResetPX, 'formResetPX',  this.frmMainRefs.formResetPX , 'formResetPX', true, 'warning')
        this.loading_Process = false
        return false
      }
      await this._waitRequestProc(1000)
      // ------------------
      this.currentView = 'sc_login'
      this.loading_Process = false
      return true
    }, 
    onStepFormReset_passX () { 
      //------
    },
    pasox_onBlurNewPass (fieldName , event){ 
      if (this.frmMainErrors.paso_sc_pass !== ''){ 
        this.frmMainErrors.paso_sc_pass = ''
      }
    },
    // pantalla de login 
    onStepFormSubmit_loginX: async function (){
      var mloginModel = this.getModelRequest('_userlogin')
      var mpModel = this.getModelRequest('userProfile')
      var reqRes = { login: [], profile: [] }
      var reqErr = { login: 0, profile: 0 }
      // ------------------
      this.loading_Process = true
      // ------------------
      var myLogConfig = this.createAxiosConfig ('post', mloginModel, { 
        useData: true ,
        data: {
          grant_type: 'password',
          username: '' + this.loginData.email,
          password: '' + this.loginData.password
        } 
      }, false, null)
      // ------------------
      await this.doRequestByPromise(this.$axios, 'userlogin', reqRes.login, myLogConfig).then((resx) => {
        var tmpresponseData = reqRes.login[reqRes.login.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.login += 1
        }
      })
      if (reqErr.login > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.ex_handlerErrorFormNoSessionRequest (reqRes.login[0], reqErr.login, this.$refs.formCannaLog, 'formCannaLog',  this.frmMainRefs.formCannaLog , 'formCannaLog', false)
        this.loading_Process = false
        return false
      }
      // ------------------
      var loginData = reqRes.login[0].data.data
      var myToken = loginData.access_token
      this.$store.commit('myapp/setloginData', { token: myToken }) //setloginData
      this.$store.commit('myapp/setAppUserData', { access_token: myToken })
      await this._waitRequestProc(100)
      // ------------------
      // Hasta aqui estamos bien y obtenemos los datos de profile 
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, { test: 11, expand:'experienciaLaboral,userPerfilLaboralFiltro' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
        var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profile += 1
        }
      })
      if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
        this.$cannaDebug('-- loginscreen -- Error al cargar el profile -- ')
        this.loading_Process = false
        return false
      }
      var profileData = reqRes.profile[0].data.data
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await this._waitRequestProc(200)
      // ------------------
      // Actualizamos o creamos la cookie principal de la app -- update cookie DATA
      var dataObject = { logIn:true, user: profileData.id, token: myToken }
      //-----------------
      var testCks = null 
      var mainCKExist = this.$q.cookies.has('CannaV2Public')
      if (mainCKExist === true){
        var testCDx = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2Public' )
        await this._waitRequestProc(400)
      }
      await this._waitRequestProc(200)
      var testCks = this.cannack_createCookie (this.$q.cookies, 'CannaV2Public', dataObject, true)
      this.$cannaApiService.$cannaAxios.defaults.headers.common.Authorization = `Bearer ${loginData.access_token}`
      this.$cannaDebug('-- login recover screen -- ck created && updated 0002 -- ' + testCks)
      // ------------------
      this.loading_Process = false
      this.$forceUpdate()
      // Verificamos el estatus de la persona 
      var newRuta = ''
      newRuta= '/dashboard'
      this.$router.push(newRuta)
      return true
    },
    onStepFormReset_loginX () {
      //------
    },
    pasox_onBlurLogin (fieldName , event){ 
      if (this.frmMainErrors.paso_sc_login !== ''){ 
        this.frmMainErrors.paso_sc_login = ''
      }
    },
  },
  beforeCreate () {
    //this.$cannaDebug('-- recoverScreen -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- recoverScreen -- created')
    this.zloadingData = true 
    this.$q.iconSet.field.error = 'r_report_problem'
		this.resetInfo()
    this.currentView = this.initialView
    this.layoutParent.$set(this.layoutParent, 'headLoginW','464px')
  },
  beforeMount () {
    // this.$cannaDebug('-- recoverScreen --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- recoverScreen -- mounted')
    this.zloadingData = false
  },
  beforeUpdate () {
    // this.$cannaDebug('-- recoverScreen -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- recoverScreen -- updated')
  },
  activated () {
    this.$cannaDebug('-- recoverScreen -- activated')
		this.resetInfo()
    this.currentView = this.initialView
    this.$forceUpdate()
  },
  deactivated () {
    // this.$cannaDebug('-- recoverScreen -- deactivated')
    this.pomodoro.timerX = null;
    this.pomodoro.canSendLink = true;
    this.pomodoro.timerCount = 60;    
  },
  beforeDestroy () {
    // this.$cannaDebug('-- recoverScreen -- beforeDestroy')
    this.pomodoro.timerX = null;
    this.pomodoro.canSendLink = true;
    this.pomodoro.timerCount = 60;    
  },
  destroyed () {
    // this.$cannaDebug('-- recoverScreen -- destroyed')
  }
}
</script>